<template>
  <div class="sellerConfirmation">
    <Header :isGoBack="false" :title="title" theme="light" />
    <div class="box__row" v-if="isCheck == false">
      <CountDown :minute="minute" :sec="sec" />
      <div class="wrap__content">
        <div class="content_2">
          <div class="flex-sb mb_8px">
            <div>
              卖家名称 : <span>{{ logData.traderName }}</span>
            </div>
            <div class="save" v-if="isTraderRelations != true" @click="postTraderRelations({ traderId: logData.traderId })">
              [收藏]
            </div>
          </div>
          <div class="mb_8px">
            订单金额 : <span>¥ {{ logData.orderAmount }}</span>
          </div>
          <div class="flex-sb mb_2em">
            <div>
              订单编号 : <span>{{ logData.orderNo }}</span>
            </div>
            <div v-clipboard:copy="logData.orderNo" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
              [复制]
            </div>
          </div>

          <div style="display:none">
            <div class="mb_8px">
              收款方式 : <span>{{ logData.channelName }}</span>
            </div>
            <div class="mb_8px">
              收款用户 : <span>{{ logData.traderAccountName }}</span>
            </div>
            <div class="flex-sb">
              <div>
                收款帐号 : <span>{{ logData.traderAccount }}</span>
              </div>
              <div v-clipboard:copy="logData.userAccount" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
                [复制]
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <div
          @click="
            $router.push({
              path: `/message/${encodeURIComponent(logData.traderName)}/C2Ctrader_im_${logData.traderId}/trader_im_${logData.traderId}`,
            })
          "
        >
          <img src="../../assets/images/public/ic_payment_information.png" alt="" />
        </div>
        <div @click="payPhone(logData.userMobile)">
          <img src="../../assets/images/public/ic_payment_phone.png" alt="" />
        </div>
        <div
          @click="
            $router.push({
              name: 'cancelOrder',
              params: { orderNo: logData.orderNo },
            })
          "
        >
          取消交易
        </div>
      </div>
    </div>

    <div class="zone__main__02" v-else>
      <div class="content_2">
        <div class="box__close mb_8px">
          <div class="box__img" @click="$router.push('/')">
            <img src="../../assets/images/public/ic_payment_tick.png" alt="" />
          </div>
          <div class="text__01">订单完成</div>
        </div>
        <div class="w100 flex-sb mb_8px">
          <div>
            卖家名称 : <span>{{ logData.traderName }}</span>
          </div>
          <div v-clipboard:copy="logData.orderNo" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
            [复制]
          </div>
        </div>
        <div class="w100 mb_8px">
          订单金额 : <span>{{ logData.orderAmount }}</span>
        </div>
        <div class="w100 flex-sb mb_8px">
          <div>
            订单编号 : <span>{{ logData.orderNo }}</span>
          </div>
          <div v-clipboard:copy="logData.orderNo" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
            [复制]
          </div>
        </div>
        <div class="w100 mb_8px">
          创建时间 : <span>{{ logData.createdAt }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import CountDown from "@/components/CountDown.vue";
import { Message } from "element-ui";
import { mapState, mapActions } from "vuex";
export default {
  name: "sellerConfirmation",
  components: {
    Header,
    CountDown,
  },
  data() {
    return {
      title: "等待卖家确认",
      data: [],
      totalSec: 0,
      timePush: 0,
      minute: 0,
      sec: 0,
      timeCount: 0,
      isTraderRelations: false,
      isCheck: false,
    };
  },
  computed: {
    ...mapState("orders", ["countdown", "buySellerSubmitData", "logData"]),
    ...mapState("trader", ["traderRelationsData"]),
    ...mapState("message", ["msgList", "isTimLogin"]),
  },

  watch: {
    totalSec(e) {
      this.getLogId(this.$route.query.orderId);
      if (e == 0) {
        clearInterval(this.timeCount);
        const self = this;
        self.timePush = setInterval(() => {
          self.getLogId(this.$route.query.orderId);
        }, 1000);
      }
    },
    buySellerSubmitData(e) {
      this.totalSec = e["timeLeft"];
    },
    logData(e) {
      if (e.status == 5 || e.status == 1) {
        clearInterval(this.timeCount);
        // this.$router.push({
        //   path: `/orderInfo?orderNo=${e.orderNo}&traderAccountId=${e.traderAccountId}&amount=${e.orderAmount}&orderId=${e.id}`,
        //   query: {
        //     orderNo: this.logData.orderNo,
        //     orderId: this.logData.id,
        //   },
        // });
      }
    },
    traderRelationsData() {
      this.findTraderRelations();
    },
    countdown() {
      this.time();
    },
    ["logData.abnormal"]: {
      handler(e) {
        if (e == 1) {
          clearInterval(this.timePush);
          this.$router.push({
            path: "/appealDetail",
            query: {
              orderNo: this.logData.orderNo,
              orderId: this.logData.id,
            },
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // this.init();
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("orders", ["getCountdown", "putBuySellerSubmit", "getLogId"]),
    ...mapActions("trader", ["getTraderRelations", "postTraderRelations"]),
    ...mapActions("message", ["getMsgList"]),
    init() {
      if (this.isTimLogin) this.getMsgList();
      this.data = {
        orderNo: this.$route.query.orderNo,
      };
      this.getTraderRelations({});
      this.getCountdown(this.data).then((res) => {
        if (res.code == "fail") {
          this.$router.push({
            path: "/orderInfo",
            query: {
              orderNo: this.logData.orderNo,
              orderId: this.logData.id,
            },
          });
        }
      });
      this.getLogId(this.$route.query.orderId).then((res) => {
        if (res.data.abnormal == 1) {
          this.$router.push({
            path: "/appealDetail",
            query: {
              orderNo: this.logData.orderNo,
              orderId: this.logData.id,
            },
          });
        }
      });
      // this.putBuySellerSubmit(this.data);
    },
    payPhone(num) {
      location.href = `tel:${num}`;
    },
    time() {
      const self = this;
      self.totalSec = this.countdown["timeLeft"];
      self.timeCount = setInterval(function() {
        self.totalSec = self.totalSec - 1;
        self.minute = parseInt(Math.floor(self.totalSec / 60));
        self.sec = parseInt(Math.floor(self.totalSec % 60));
      }, 1000);
    },
    handleCopySuccess() {
      Message({
        message: "复制成功",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    handleCopyError() {
      Message({
        message: "复制失败",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    findTraderRelations() {
      const self = this;
      self.traderRelationsData.forEach((e) => {
        if (e.traderId == this.logData.traderId) {
          self.isTraderRelations = true;
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.timeCount);
    clearInterval(this.timePush);
  },
};
</script>
<style lang="scss" scoped>
.sellerConfirmation {
  @media (min-width: 480px) {
    overflow: scroll;
    height: 100vh;
    padding-bottom: 4rem;
  }
}

.wrap__content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 480px) {
    justify-content: center;
  }

  .content_2 {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-top: 1.5rem;
    flex-wrap: wrap;
    font-size: 0.8rem;

    & > div {
      width: 100%;
      text-align: left;

      color: var(--gray);

      div {
        color: var(--gray);
      }

      span {
        color: var(--black);
      }

      .save {
        color: var(--orange);
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  // left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // max-width: 414px;
  margin: 0 auto;

  @media (min-width: 480px) {
    justify-content: center;
  }

  div:nth-child(1),
  div:nth-child(2) {
    width: 10%;
    margin-left: 1rem;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  div:nth-child(3) {
    background-color: var(--light-gray);
    color: var(--gray);
    margin-left: 1rem;
    width: 67%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 0.8rem;
  }
}

.zone__main__02 {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 1rem;

  .content_2 {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-top: 1.5rem;
    flex-wrap: wrap;
    font-size: 0.8rem;

    & > div {
      width: 100%;
      text-align: left;
      color: var(--gray);

      div {
        color: var(--gray);
      }

      span {
        color: var(--black);
      }

      .save {
        color: var(--orange);
      }
    }

    .box__close {
      width: 100%;

      .box__img {
        width: 2rem;
        margin: auto;

        img {
          width: 100%;
        }
      }

      .text__01 {
        margin-top: 0.5rem;
        color: var(--font-gray);
        text-align: center;
      }
    }
  }
}
</style>
